import { Icon } from '@blueprintjs/core';
import { FC, LegacyRef } from 'react';

import { sprinkles } from 'components/ds';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';

interface Props {
  type: string;
  displayName: string;
  containerRef?: LegacyRef<HTMLDivElement>;
}

export const Column: FC<Props> = ({ type, displayName, containerRef }) => {
  return (
    <div className={COLUMN_CLASS_NAME} ref={containerRef}>
      <Icon icon={SCHEMA_DATA_TYPES_BY_ID[type].icon} />
      <div
        className={sprinkles({ marginLeft: 'sp1' })}
        // Using this because displayName could contain bolding
        dangerouslySetInnerHTML={{ __html: displayName }}
      />
    </div>
  );
};

const COLUMN_CLASS_NAME = sprinkles({
  flexItems: 'alignCenter',
  cursor: 'grab',
  paddingY: 'sp.5',
  paddingX: 'sp1',
  borderRadius: 3,
  backgroundColor: { hover: 'lightBlue' },
});
