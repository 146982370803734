import { FC, useMemo } from 'react';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { sprinkles } from 'components/ds';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';

import { DashboardDrilldownEntryDetailSection } from './DashboardDrilldownEntryDetailSection';

interface Props {
  dashboardIdToNameMap: Record<number, string>;
  drilldownEntryPointMap: Record<string, DrilldownEntryPointInfo>;
}

export const DashboardDrilldownsSection: FC<Props> = ({
  dashboardIdToNameMap,
  drilldownEntryPointMap,
}) => {
  const dashboardIds = useMemo(
    () => new Set(Object.keys(dashboardIdToNameMap)),
    [dashboardIdToNameMap],
  );

  const validDrilldownEntryPoints = useMemo(() => {
    return Object.entries(drilldownEntryPointMap).filter(([, drilldownEntryPoint]) =>
      dashboardIds.has(String(drilldownEntryPoint.destinationDashboardId)),
    );
  }, [dashboardIds, drilldownEntryPointMap]);

  const invalidDrilldownEntryPoints = useMemo(() => {
    return Object.entries(drilldownEntryPointMap).filter(
      ([, drilldownEntryPoint]) =>
        !dashboardIds.has(String(drilldownEntryPoint.destinationDashboardId)),
    );
  }, [dashboardIds, drilldownEntryPointMap]);

  if (validDrilldownEntryPoints.length === 0 && invalidDrilldownEntryPoints.length === 0) {
    // Do not render this section if there are no drilldown entry points.
    return null;
  }

  const hasValidDrilldownEntryPoints = validDrilldownEntryPoints.length > 0;
  const hasInvalidDrilldownEntryPoints = invalidDrilldownEntryPoints.length > 0;

  return (
    <ConfigSection icon="chart-bar" title="Dashboard drilldowns" variant="header1">
      <div className={sprinkles({ marginTop: 'sp1', paddingX: 'sp2' })}>
        {hasValidDrilldownEntryPoints ? (
          <div className={sprinkles({ heading: 'h4' })}>Valid entry points</div>
        ) : null}
        <DashboardDrilldownEntryDetailSection
          dashboardIdToNameMap={dashboardIdToNameMap}
          drilldownEntryPoints={validDrilldownEntryPoints}
        />
        {hasInvalidDrilldownEntryPoints ? (
          <div className={sprinkles({ heading: 'h4' })}>Invalid entry points</div>
        ) : null}
        <DashboardDrilldownEntryDetailSection
          dashboardIdToNameMap={dashboardIdToNameMap}
          drilldownEntryPoints={invalidDrilldownEntryPoints}
        />
      </div>
    </ConfigSection>
  );
};
