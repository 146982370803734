import { FC } from 'react';
import { useDrag } from 'react-dnd';

import { ItemTypes } from 'constants/dragAndDrop';

import { Column } from './Column';

type Props = {
  name: string;
  type: string;
  disableDrag?: boolean;
  displayName: string;
};

export const DraggableColumn: FC<Props> = ({ name, type, disableDrag, displayName }) => {
  const dragData = useDrag({
    item: { type: ItemTypes.CONFIGURATION_COLUMN, data: { name, type } },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !disableDrag,
  });

  return <Column containerRef={dragData[1]} displayName={displayName} type={type} />;
};
